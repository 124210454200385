import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { AccountForm } from '../../../../components/AccountForm';
import { UserRepositoryType } from '../../../../contracts/repositories';
import CompanyDataForm from '@profile/shared/components/CompanyDataForm/CompanyDataForm.vue';
import { UserDataForm } from '@profile/shared/components/UserDataForm';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let ChangeCompanyDataView = class ChangeCompanyDataView extends Vue {
    constructor() {
        super(...arguments);
        this.formName = 'changeCompanyDataForm';
        this.successMessage = '';
        this.companyData = null;
        this.userData = null;
        this.isActing = false;
    }
    get meData() {
        return this.me ? this.me.companyData : null;
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    initFormData() {
        var _a, _b, _c, _d, _e;
        this.userData = {
            firstName: this.me && 'firstName' in this.me ? this.me.firstName : '',
            lastName: this.me && 'lastName' in this.me ? this.me.lastName : ''
        };
        this.companyData = {
            vatNumber: this.meData && 'vatNumber' in this.meData ? this.meData.vatNumber : '',
            organization: this.meData && 'organization' in this.meData ? (_a = this.meData) === null || _a === void 0 ? void 0 : _a.organization : '',
            city: this.meData && 'city' in this.meData ? (_b = this.meData) === null || _b === void 0 ? void 0 : _b.city : '',
            postalCode: this.meData && 'postalCode' in this.meData ? (_c = this.meData) === null || _c === void 0 ? void 0 : _c.postalCode : '',
            country: this.meData && 'country' in this.meData ? (_d = this.meData) === null || _d === void 0 ? void 0 : _d.country : '',
            address: this.meData && 'address' in this.meData ? (_e = this.meData) === null || _e === void 0 ? void 0 : _e.address : ''
        };
    }
    onCancel() {
        this.$router.push({ name: 'profile.my-data' });
    }
    async onSubmit() {
        this.isActing = true;
        this.successMessage = '';
        const formData = {
            ...this.userData,
            companyData: this.companyData
        };
        try {
            await this.meRepository.update(formData);
            this.$store.commit('profile/SET_ME_DETAILS', { ...this.me, ...formData });
            this.successMessage = `${this.$t('modules.profile.views.change-company-data.success')}`;
        }
        catch (error) {
            this.$logger(error, 'error');
        }
        finally {
            this.isActing = false;
        }
    }
};
__decorate([
    Inject(UserRepositoryType)
], ChangeCompanyDataView.prototype, "meRepository", void 0);
__decorate([
    Watch('me')
], ChangeCompanyDataView.prototype, "initFormData", null);
ChangeCompanyDataView = __decorate([
    Component({
        name: 'ChangeCompanyDataView',
        components: { UserDataForm, AccountForm, CompanyDataForm, Screen },
        mounted() {
            this.initFormData();
        }
    })
], ChangeCompanyDataView);
export { ChangeCompanyDataView };
export default ChangeCompanyDataView;
