import { __decorate } from "tslib";
import { Component, Vue, PropSync } from 'vue-property-decorator';
let CompanyDataForm = class CompanyDataForm extends Vue {
};
__decorate([
    PropSync('companyData', { type: Object, required: true })
], CompanyDataForm.prototype, "currentCompanyData", void 0);
CompanyDataForm = __decorate([
    Component({
        name: 'CompanyDataForm'
    })
], CompanyDataForm);
export { CompanyDataForm };
export default CompanyDataForm;
