import { __decorate } from "tslib";
import { Component, Vue, PropSync } from 'vue-property-decorator';
let UserDataForm = class UserDataForm extends Vue {
};
__decorate([
    PropSync('userData', { type: Object, required: true })
], UserDataForm.prototype, "currentUserData", void 0);
UserDataForm = __decorate([
    Component({
        name: 'UserDataForm'
    })
], UserDataForm);
export { UserDataForm };
export default UserDataForm;
